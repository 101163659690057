const additionalPackagesChannels = [
    {
        packageName: "FilmBox",
        id: 4408,
        channels: [
            "360 Tunebox",
            "Docubox (HD)",
            "Fastnfunbox (HD)",
            "Fightbox (HD)",
            "Filmbox Art House",
            "Filmbox Extra (HD)",
            "Filmbox Stars",
            "Filmbox Premium (HD)",
            "Fashionbox",
            "Gametoon (HD)",
        ],
        description: "Programski paket Film Box ponuja vse od športa, glasbe, filmov in dokumentarnih vsebin iz celega sveta in je primeren za vse, ki ne posegate po tipičnih Hollywoodskih vsebinah. Vključuje tudi program za ljubitelje video igric."
    },
    {
        packageName: "Cinestar Premiere",
        id: 2475,
        channels: [
            "Cinestar Premiere (HD)",
            "Cinestar Premiere 2 (HD)"
        ],
        description: "Na programih Cinestar Premiere se vrtijo naslovi, ki so bili predvajani na velikih platnih pred približno enim letom. Od Hollywoodskih uspešnic do filmov evropske kinematografije, tudi v visoki ločljivosti."
    },
    {
        packageName: "Paket Pink",
        id: 298,
        channels: [
            "Pink Koncert",
            "Pink’n’Roll",
            "Pink Hits",
            "Pink Extra",
            "Pink Plus",
            "Pink Reality",
            "Pink Serije",
            "Pink Zabava",
            "Pink Film",
            "Pink Folk",
            "Pink World",
        ],
        description: "Paket Pink ponuja vsebine za vse starosti in okuse: filmski TV programi s filmi in serijami, otroški TV program z risankami in zabavnimi vsebinami za najmlajše, glasbeni TV programi za ljubitelje narodne in popularne glasbe in zabavni TV programi Lifestyle z resničnostnimi šovi, vsebinami o modi in aktualnih trendih."
    },
    {
        packageName: "Yugo",
        id: 418,
        channels: [
            "RTL Kockica (HD)",
            "FEN TV",
            "FenFolk TV",
            "Hit TV (HD)",
            "Jugoton TV",
            "Non Stop (HD)",
            "24 Vesti",
            "Vijesti",
            "RTL 2 HR (HD)",
            "RTL Living (HD)",
            "RTL Televizija (HD)",
            "TV Slon",
            "Prva World (HD)",
            "Hayat Folk Box",
            "Folk Plus",
            "RTV Shqiptar",
            "Tring 7",
            "Tring Action (HD)",
            "Tring Shqip",
            "Tring Tring",
            "Tring Vizion+ (HD)",
            "Alfa TV BiH",
            "BHT 1 (HD)",
            "Al Jazeera Balkans (HD)",
            "Elta TV",
            "Hayat",
            "Hema",
            "NTV IC Kakanj (HD)",
            "RTRS",
            "TV Sarajevo (HD)",
            "Vikom TV",
            "BIR TV (HD)",
            "OTV (HD)",
            "Narodna TV (HD)",
            "Mreža TV (HD)",
            "TV Jadran",
            "Z1 televizija (HD)",
            "Zdrava Televizija",
            "Alfa TV MAK",
            "Alsat Macedoniae",
            "Kanal 5",
            "MTV 1",
            "MTV 2",
            "MTV 3",
            "Naša TV",
            "Sonce TV",
            "Telma TV",
            "TV Sitel",
            "RTV Atlas",
            "B92 (HD)",
            "Happy TV",
            "KCN",
            "KCN Music",
            "Prva (HD)",
            "RTS 1 (HD)",
            "RTS 2 (HD)",
            "TV Duga Novi Sad",
            "Prva Max (HD)",
            "Prva Files (HD)",
            "Prva Kick (HD)",
            "Prva Life (HD)",
            "Prva Plus (HD)",
            "Happy Reality",
            "Happy Reality 2",
            "KCN 3",
            "Hayat Stil i život (HD)",
            "Hayat Love box (HD)"
        ],
        description: "Paket Yugo 55+ prinaša filme, resničnostne, informativne in zabavne oddaje, serije ter glasbo na več kot 55 programih z območja bivše Jugoslavije. Zabavne vsebine za vse generacije v srbščini, hrvaščini in makedonščini."
    },
    {
        packageName: "RUS 5+",
        id: 742,
        channels: [
            "Vremya",
            "Dom Kino",
            "Karousel",
            "Muzika Pervogo",
            "TNT Music",
            "Channel One Russia",
            "Telecafe"
        ],
        description: "Ljubitelji ruskih TV programov jih boste v paketu Rus 5+ našli kar 7. Poleg zabavnih, informativnih, glasbenih in filmskih vsebin, vključuje tudi program za najmlajše gledalce."
    },
    {
        packageName: "HUN 5+",
        id: 5882,
        channels: [
            "Duna (HD)",
            "Duna World (HD)",
            "M1 (HD)",
            "M2 (HD)",
            "M5 (HD)"
        ],
        description: "Paket HUN 5+ je nepogrešljiv dodatek za vse, ki imajo radi madžarski jezik. Ne glede na to ali govorite tekoče madžarsko ali se jezika šele učite, boste med programi našli pester nabor vsebin različnih tematik."
    },
    {
        packageName: "Športni paket",
        id: 5770,
        channels: [
            "Šport TV (HD)",
            "Šport TV 2 (HD)",
            "Šport TV 3 (HD)",
            "Arena Sport 1 Premium (HD)",
            "Arena Sport 1 (HD)",
            "Arena Sport 2 (HD)",
            "Arena Sport 3 (HD)",
            "Arena Sport 4 (HD)",
            "Arena Fight (HD)",
            "TV Arena Esport (HD)",
            "Eurosport (HD)",
            "Eurosport 2 (HD)",
        ],
        description: "Športni paket s programi Arena Sport, Šport TV in Eurosport bodo ljubitelje športa razveseljevali zlasti ob večjih in odmevnih športnih dogodkih."
    },
    {
        packageName: "Šport TV",
        id: 5768,
        channels: [
            "Šport TV (HD)",
            "Šport TV 2 (HD)",
            "Šport TV 3 (HD)"
        ],
        description: "Na Šport TV si lahko ogledate zadnje novice iz sveta športa in spremljate veliko število zanimivih in poučnih športnih magazinskih oddaj."
    },
    {
        packageName: "Arena Sport",
        id: 5769,
        channels: [
            "Arena Fight (HD)",
            "Arena Sport 1 Premium (HD)",
            "Arena Sport 1 (HD)",
            "Arena Sport 2 (HD)",
            "Arena Sport 3 (HD)",
            "Arena Sport 4 (HD)",
            "TV Arena Esport (HD)",
        ],
        description: "Paket Arena Sport nadušuje predvsem košarkaške navdušence, saj lahko v živo spremljajo ligo NBA, vključno z našima košarkaskima junakoma Luko Dončićem in Goranom Dragićem. Na programih prenašajo tudi ligo ABA, tuje nogometne lige, tenis, rokomet in hokej, na sporedu pa je tudi bogat nabor oddaj in celovečernih dokumentarnih filmov."
    },
    {
        packageName: "Da Vinci",
        id: 6238,
        channels: [],
        description: "Odkrijte čarobnost učenja skozi zabavo z našo programsko videoteko Da Vinci! Z njo bodo vašemu otroku odprta vrata do raznolikega sveta poučnih in zabavnih vsebin, dostopnih kar v slovenskem jeziku. Razveselite svojega malčka in mu omogočite, da se uči na najbolj navdihujoč način!"
    },
    {
        packageName: "DuckTV",
        id: 5703,
        channels: [],
        description: "Duck TV prinaša zabavo za najmlajše gledalce. Animirane epizode pritegnejo pozornost otrok, prikazujejo pa različna razmerja med liki ter odnose med otroci, starši in prijatelji."
    },
    {
        packageName: "English Club",
        id: 6043,
        channels: [],
        description: "English Club TV niza lekcije učenja angleškega jezika. Vsebine so pripravljene za učenje jezika na različnih stopnjah – za začetnike in perfekcioniste, posebne lekcije pa so prilagojene učenju jezika za najmlajše. Naročniki si lahko lekcij ogledate v T-2 videoteki."
    },
    {
        packageName: "Mezzo",
        id: 5888,
        channels: [],
        description: "Sprostite se ob zvokih klasične, pa tudi nekoliko sodobnejše glasbe. Ljubitelji opere in baleta boste v videoteki Mezzo nedvomno našli posnetke, ki bodo pobožali vašo dušo."
    },
    {
        packageName: "Minimax VOD",
        id: 3434,
        channels: [],
        description: "Videoteka Minimax vas popelje v nepozaben svet dogodivščin vaših najbolj priljubljenih risanih junakov. Mesečno ponuja več kot 100 ur različnih risank v katerih srečamo Petra Pana, Gasilca Samota, Obutega mačka in še mnoge druge."
    },
    {
        packageName: "Pickbox NOW",
        id: 3436,
        channels: [],
        description: "V videoteki Pickbox Now si brez reklam oglejte uspešnice svetovne in evropske produkcije. Vse serije in filmi so prevedeni v slovenščino, hrvaščino, srbščino in makedonščino. Več kot 3.000 ur vsebin, nekaj iz vsakega žanra."
    },
    {
        packageName: "TimmyKids TV",
        id: 5632,
        channels: [],
        description: "Zabavne in poučne vsebine za predšolske otroke na enem mestu. Z vašimi najmlajšimi vstopite v svet živali, narave in otroškega rajanja. Skupaj bomo telovadili, se učili risati, spoznavali vozila, odkrivali oblike, peli, brali in še mnogo več."
    },
    {
        packageName: "Zaspanček Oli",
        id: 6300,
        channels: [],
        description: "Zaspanček Oli ne bi želel postati nekdo, ki bi vaš skupni čas z otrokom nadomestil, je pa lahko dober prijatelj, kadar ste sami po več prebranih pravljicah bolj zaspani od otroka, med vožnjo v avtomobilu, kadar ni časa za branje ali enostavno za sprostitev med dnevom."
    }
];

export default additionalPackagesChannels;
